import React from "react"
import styled from "styled-components"
import {Link} from "gatsby"
import PropTypes from "prop-types";

const NavItem = styled(Link)`
  text-decoration: none;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    content: ".";
    color: transparent;
    background:#ffc107;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color:#ffc107;

    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 10;
  }
`
const NavbarLinks = (props) => {
	return (
		<>
			<NavItem to="#inicio"
							 onClick={props.handleToggle ? props.handleToggle : null}
			>inicio</NavItem>
			<NavItem to="#servicios"
							 onClick={props.handleToggle ? props.handleToggle : null}
			>Servicios</NavItem>
			<NavItem to="#clientes"
							 onClick={props.handleToggle ? props.handleToggle : null}

			>Clientes</NavItem>
			<NavItem to="#contacto"
							 onClick={props.handleToggle ? props.handleToggle : null}
			>Contacto</NavItem>
		</>
	)
}

NavbarLinks.propTypes = {
	handleToggle: PropTypes.func,
};

export default NavbarLinks
