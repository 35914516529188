import React from "react";
import styled from "styled-components";

const ContainerGear = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @-webkit-keyframes clockwise {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes clockwise {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes counter-clockwise {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }

    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
  @keyframes counter-clockwise {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
`;

const GearboxDiv = styled.div`
  background: transparent;
  height: 300px;
  width: 300px;
  position: relative;
  border: none;
  overflow: hidden;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
`;

const GearOne = styled.div`
  position: absolute;
  height: 60px;
  width: 60px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  top: 13px;
  left: 15px;

  :after {
    content: "";
    position: absolute;
    height: 36px;
    width: 36px;
    -moz-border-radius: 36px;
    -webkit-border-radius: 36px;
    border-radius: 36px;
    background: white;
    top: 50%;
    left: 50%;
    margin-left: -18px;
    margin-top: -18px;
    z-index: 3;
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
  }
`;

const GearTwo = styled.div`
  position: absolute;
  height: 60px;
  width: 60px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  top: 60px;
  left: 65px;

  :after {
    content: "";
    position: absolute;
    height: 36px;
    width: 36px;
    -moz-border-radius: 36px;
    -webkit-border-radius: 36px;
    border-radius: 36px;
    background: white;
    top: 50%;
    left: 50%;
    margin-left: -18px;
    margin-top: -18px;
    z-index: 3;
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
`;

const GearThree = styled.div`
  position: absolute;
  height: 60px;
  width: 60px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  top: 118px;
  left: 226px;

  :after {
    content: "";
    position: absolute;
    height: 36px;
    width: 36px;
    -moz-border-radius: 36px;
    -webkit-border-radius: 36px;
    border-radius: 36px;
    background: white;
    top: 50%;
    left: 50%;
    margin-left: -18px;
    margin-top: -18px;
    z-index: 3;
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
`;

const GearFourLarge = styled.div`
  position: absolute;
  top: 13px;
  left: 133px;
  height: 120px;
  width: 120px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;

  :after {
    content: "";
    position: absolute;
    background: white;
    top: 50%;
    left: 50%;
    z-index: 3;
    -moz-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    -webkit-box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1), inset 0 2px 0 0 #080808, inset 0 -1px 0 0 #888888;
    height: 96px;
    width: 96px;
    -moz-border-radius: 48px;
    -webkit-border-radius: 48px;
    border-radius: 48px;
    margin-left: -48px;
    margin-top: -48px;
  }
`;


const GearInnerOne = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #555555;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-animation: counter-clockwise 3s linear infinite both;
  -moz-animation: counter-clockwise 3s linear infinite both;
`;

const GearInnerTwo = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #555555;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-animation: clockwise 3s linear infinite both;
  -moz-animation: clockwise 3s linear infinite both;
`;

const GearInnerThree = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #555555;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-animation: clockwise 3s linear infinite both;
  -moz-animation: clockwise 3s linear infinite both;
`;

const GearInnerLarge = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #555555;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  border: 1px solid rgba(255, 255, 255, 0.1);
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
  -webkit-animation: counter-clockwise 6s linear infinite both;
  -moz-animation: counter-clockwise 6s linear infinite both;
`;

const GearboxBar = styled.div`
  background: #555555;
  height: 16px;
  width: 76px;
  position: absolute;
  left: 50%;
  margin-left: -38px;
  top: 50%;
  margin-top: -8px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);

  :nth-child(2) {
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  :nth-child(3) {
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }

  :nth-child(4) {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  :nth-child(5) {
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  :nth-child(6) {
    -moz-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
  }
`;

const GearboxBarLarge = styled.div`
  background: #555555;
  height: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  margin-left: -68px;
  width: 136px;

  :nth-child(2) {
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  :nth-child(3) {
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
  }

  :nth-child(4) {
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  :nth-child(5) {
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  :nth-child(6) {
    -moz-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg);
  }
`;

const Gearbox = () => {
	return (
		<ContainerGear>
			<GearboxDiv>
				<GearOne>
					<GearInnerOne>
						<GearboxBar></GearboxBar>
						<GearboxBar></GearboxBar>
						<GearboxBar></GearboxBar>
					</GearInnerOne>
				</GearOne>
				<GearTwo>
					<GearInnerTwo>
						<GearboxBar></GearboxBar>
						<GearboxBar></GearboxBar>
						<GearboxBar></GearboxBar>
					</GearInnerTwo>
				</GearTwo>
				<GearThree>
					<GearInnerThree>
						<GearboxBar></GearboxBar>
						<GearboxBar></GearboxBar>
						<GearboxBar></GearboxBar>
					</GearInnerThree>
				</GearThree>
				<GearFourLarge>
					<GearInnerLarge>
						<GearboxBarLarge></GearboxBarLarge>
						<GearboxBarLarge></GearboxBarLarge>
						<GearboxBarLarge></GearboxBarLarge>
						<GearboxBarLarge></GearboxBarLarge>
						<GearboxBarLarge></GearboxBarLarge>
						<GearboxBarLarge></GearboxBarLarge>
					</GearInnerLarge>
				</GearFourLarge>
			</GearboxDiv>
		</ContainerGear>
	)
}

export default Gearbox;
