import React from "react";
import styled from "styled-components";
import Gearbox from "./Gearbox";

const ContainerInicio = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #000;
    padding-top: 100px;
    padding-bottom: 50px;
    @media (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 0;
    }
`;

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: 576px) {
        width: 540px;
    }
    @media (min-width: 768px) {
        width: 720px;
    }
    @media (min-width: 992px) {
        width: 960px;
        flex-direction: row;
    }
    @media (min-width: 1200px) {
        width: 1140px;
    }
`;

const Title = styled.h1`
    margin-bottom: 1.5rem !important;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 1.2;
    padding: 10px;
    text-align: justify;
    @media (max-width: 1200px) {
        font-size: calc(1.375rem + 1.5vw);
				padding: 15px;
    }
`;

const Subheadline = styled.h2`
    font-size: 1.5rem;
    font-weight: 400;
    color: #6c757d;
    margin-bottom: 1rem;
    padding: 0 15px;
    text-align: center;
`;

const SpanTitle = styled.span`
    color: #0d6efd;
    font-weight: 600;
`;

const ColInicio = styled.div`
    width: 100%;
    margin-bottom: 5vh;
`;

const Parragraph = styled.p`
    color: #6c757d;
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    padding: 0 15px 0.5rem;
    text-align: justify;
`;

const BotonContainer = styled.div`
    max-width: 300px; /* Ajusta el ancho según sea necesario */
    width: 80%;
    background-color: #ffc107;
    border-color: #ffc107;
    border-radius: 0.25rem;
    margin: 15px;
    padding: 0.7rem 0.75rem;
`;

const Boton = styled.a`
    color: #000;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1.5;
    transition: none;
`;

const Arrow = styled.span`
    margin-left: 5px;
`;

const Inicio = () => {
	return (
		<ContainerInicio>
			<ContainerBox>
				<ColInicio>
					<Title>
						Soluciones de Ingeniería de Precisión que Mantienen tu Industria en Movimiento{" "}
						<SpanTitle>– Desde 1993</SpanTitle>
					</Title>
					<Subheadline>
						Desde Fabricación hasta Reparación – Ingeniamos Soluciones que Perduran.
					</Subheadline>
					<Parragraph>
						Con más de 30 años de experiencia, somos líderes en servicios de mecánica industrial. Nos especializamos en la fabricación de repuestos y equipos industriales, ofreciendo calidad y excelencia en ingeniería y fabricación. ¡Confía en nosotros para mantener tu industria en marcha!
					</Parragraph>
					<BotonContainer>
						<Boton href="#contacto">
							Construyamos Juntos el Futuro de tu Industria – ¡Contáctanos Hoy!<Arrow>&#8594;</Arrow>
						</Boton>
					</BotonContainer>
				</ColInicio>
				<ColInicio>
					<Gearbox />
				</ColInicio>
			</ContainerBox>
		</ContainerInicio>
	);
};

export default Inicio;