import React from "react";
import styled from "styled-components";
import {FaEnvelope} from "@react-icons/all-files/fa/FaEnvelope";
import {FaPhone} from "@react-icons/all-files/fa/FaPhone";
import LazyLoad from "react-lazy-load";
import {FaWhatsapp} from "@react-icons/all-files/fa/FaWhatsapp";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Descripcion = styled.div`
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  //background-color: rgba(0, 0, 0, 0.15);
`;

const DescripcionMap = styled.div`
  margin: 10px;
  border-radius: 20px;
  width: 100%;
`;


const Description = styled.div`
  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  margin: 30px;
  padding: 0;
  flex-direction: row;
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
      padding: 0;
      margin: 10px 0;
  }
`;

const NoStyleLink = styled.a`
  text-decoration: none;
  color: #000;
  font-size: calc(14px + (38 - 14) * ((100vw - 300px) / (1600 - 300)));
  flex: 1;
  align-self: center;
`;

const DataContacto = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 20px;
  @media (max-width: 480px) {
    margin: 0 10px;
      flex-direction: column;
  }
`;

const DivIcon = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 50px;
`;

const Title = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0 0 50px 0;
  color: #ffc107;
  @media (max-width: 1200px) {
    font-size: calc(1.3rem + .6vw);
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ValueProposition = styled.h3`
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin: 0 0 30px 0;
  color: #6c757d;
  @media (max-width: 1200px) {
    font-size: calc(1rem + .5vw);
  }
  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const Contacto = () => {
	return (
		<Section id={"contacto"}>
			<Title>Contacto</Title>
			<ValueProposition>Hablemos Sobre Tu Próximo Gran Proyecto</ValueProposition>
			<Container>

				<Descripcion>
					<Description>
						<DivIcon>
							<FaEnvelope size={26}/>
						</DivIcon>
						<DataContacto>
							<NoStyleLink
								href="mailto:ventas@somein.cl"
							>
								ventas@somein.cl
							</NoStyleLink>
						</DataContacto>
					</Description>
					<Description>
						<FaPhone size={26}/>
						<DataContacto>
							<NoStyleLink href="tel:+56995467945">
								+56 9 9546 7945
							</NoStyleLink>
						</DataContacto>
					</Description>
					<Description>
						<FaPhone size={26}/>
						<DataContacto>
							<NoStyleLink href="tel:+56993469713">
								+56 9 9346 9713
							</NoStyleLink>
						</DataContacto>
					</Description>
					<Description>
						<FaWhatsapp size={26} color={'#25d366'} />
						<DataContacto>
							<NoStyleLink href="https://wa.me/56995467945" >
								+56 9 9546 7945
							</NoStyleLink>
						</DataContacto>
					</Description>
				</Descripcion>
				<DescripcionMap>
					<LazyLoad height={450} offsetVertical={250}>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.570092174191!2d-70.6124493852562!3d-33.61645738072679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662d7cbcd4e5899%3A0x283aeb86f442f5f8!2sMecanizado%20de%20precision%2C%20CNC%2C%20Torneria%2C%20Maestranza%2C%20Estructuras%20Metalicas%20-%20Somein%20Ltda!5e0!3m2!1ses-419!2scl!4v1573823734260!5m2!1ses-419!2scl"
							width={"100%"}
							height={"450"}
							title={"Mapa"}
							loading={"lazy"}
						></iframe>
					</LazyLoad>
				</DescripcionMap>
			</Container>
		</Section>
	);
}

export default Contacto;
