import * as React from "react"
import Navbar from "../Navbar/Navbar";
import "../styles.css"
import Inicio from "../Inicio/Inicio";
import Clientes from "../Clientes/Clientes";
import Servicios from "../Servicios/Servicios";
import Contacto from "../Contacto/Contacto";
import styled from "styled-components";

const pageStyles = {
	color: "#232129",
	fontFamily: "Roboto, sans-serif, serif",
	height: "100%",
}
const CopyRight = styled.div`
  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: #000;
`;

const Scriptdog = styled.a`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0 0 10px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;

  :hover {
    color: #ba8b00;
    cursor: pointer;
  }
`;


const IndexPage = () => {
	return (
		<>
			<Navbar/>
			<main style={pageStyles}>
				<Inicio/>
				<Servicios/>
				<Clientes/>
				<Contacto/>
				<CopyRight>
					© 2025 Somein Ltda.
				</CopyRight>
				<Scriptdog href="https://www.scriptdog.cl" target={"_blank"}>
					Hecho por Scriptdog Solutions
				</Scriptdog>
			</main>

		</>

	)
}

export default IndexPage

export const Head = () =>
	(<>
			<title>Somein</title>
			<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
			<meta name="description" content="Somein"/>
			<meta name="keywords" content="Mecanizado, Estructuras metálicas, Soldadura, Fabricación de piezas, Reparacion"/>
			<meta name="author" content="ScriptDog Solutions"/>

		</>
	)
