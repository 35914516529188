import React, { useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Lightbox from 'react-spring-lightbox';
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaChevronLeft } from "@react-icons/all-files/fa/FaChevronLeft";
import { FaCogs } from "@react-icons/all-files/fa/FaCogs";
import { FaWrench } from "@react-icons/all-files/fa/FaWrench";
import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding";
import { FaLightbulb } from "@react-icons/all-files/fa/FaLightbulb";

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 50px;
`;

const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 576px) {
        width: 540px;
    }
    @media (min-width: 768px) {
        width: 720px;
    }
    @media (min-width: 992px) {
        width: 960px;
    }
    @media (min-width: 1200px) {
        width: 1140px;
    }
`;

const Title = styled.h2`
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 0 0 50px 0;
    color: #ffc107;
    @media (max-width: 1200px) {
        font-size: calc(1.3rem + .6vw);
    }
    @media (max-width: 768px) {
        padding: 0;
    }
`;

const TitleService = styled.h2`
    align-self: flex-start;
    margin: 20px 0;
    padding: 0;
`;

const ServiceIcon = styled.div`
    font-size: 2rem;
    color: #ffc107;
    margin-bottom: 10px;
`;

const Image = styled(Img)`
    background-color: transparent;
    max-width: 500px;
    max-height: 500px;
		border-radius: 15px;
    width: 80%;
    height: 80%;
    @media (max-width: 768px) {
        max-width: 300px;
        max-height: 300px;
    }
`;

const ContainerImgLeft1 = styled.div`
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    display: flex;
    grid-area: ContainerImgLeft1;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const ContainerImgRight2 = styled.div`
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    display: flex;
    grid-area: ContainerImgRight2;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const ContainerImgLeft3 = styled.div`
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    display: flex;
    grid-area: ContainerImgLeft3;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const ContainerImgRight4 = styled.div`
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    display: flex;
    grid-area: ContainerImgRight4;
    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const ContainerTextRight1 = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    margin: 0 10%;
    grid-area: ContainerTextRight1;
    @media (max-width: 960px) {
        width: 80%;
    }
`;

const ContainerTextLeft2 = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10%;
    justify-self: center;
    grid-area: ContainerTextLeft2;
    @media (max-width: 960px) {
        width: 80%;
    }
`;

const ContainerTextRight3 = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10%;
    justify-self: center;
    grid-area: ContainerTextRight3;
    @media (max-width: 960px) {
        width: 80%;
    }
`;

const ContainerTextLeft4 = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10%;
    justify-self: center;
    grid-area: ContainerTextLeft4;
    @media (max-width: 960px) {
        width: 80%;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1.3fr 0.4fr 1.3fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 30px 0;
    grid-auto-flow: row;
    grid-template-areas:
    "ContainerImgLeft1 ContainerTextRight1 ContainerTextRight1"
    "ContainerTextLeft2 ContainerTextLeft2 ContainerImgRight2"
    "ContainerImgLeft3  ContainerTextRight3 ContainerTextRight3"
    "ContainerTextLeft4 ContainerTextLeft4 ContainerImgRight4";
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 0;
        grid-template-areas:
      "ContainerTextRight1"
      "ContainerImgLeft1"
      "ContainerTextLeft2"
      "ContainerImgRight2"
      "ContainerTextRight3"
      "ContainerImgLeft3"
      "ContainerTextLeft4"
      "ContainerImgRight4";
    }
`;

const Button = styled.a`
    background-color: #ffc107;
    border-color: #ffc107;
    color: #000;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    padding: 0.35rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: none;
    align-self: flex-start;
`;

const Arrow = styled.span`
    margin-left: 5px;
`;

const Paragraph = styled.p`
	text-align: justify;	
`;

const Header = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.3);
`;

const CloseButton = styled.button`
    background: #202020;
    border: none;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
`;

const CloseIcon = styled(FaTimes)`
    color: white;
    font-size: 1.5rem;
`;

const PrevButton = styled.button`
    background: rgba(0, 0, 0, 0.7);
    border: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PrevIcon = styled(FaChevronLeft)`
    color: white;
    font-size: 1.5rem;
`;

const NextIcon = styled(FaChevronRight)`
    color: white;
    font-size: 1.5rem;
`;

const NextButton = styled.button`
    background: rgba(0, 0, 0, 0.7);
    border: none;
    cursor: pointer;
    width: 50px;
    height: 50px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ValueProposition = styled.h3`
    font-size: 1.25rem;
    font-weight: 500;
    text-align: center;
    margin: 0 0 30px 0;
    color: #6c757d;
    @media (max-width: 1200px) {
        font-size: calc(1rem + .5vw);
    }
    @media (max-width: 480px) {
        padding: 15px;
    }
`;

const Servicios = () => {
	const [openEstructuras, setOpenEstructuras] = useState(false);
	const [openMecanizado, setOpenMecanizado] = useState(false);
	const [openFabyRep, setOpenFabyRep] = useState(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(1);

	const images = useStaticQuery(graphql`
    query {
      mecanizado: file(name: { eq: "mecanizado" }, extension: { eq: "webp" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fabyrep: file(name: { eq: "fabyrep" }, extension: { eq: "webp" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estructuras: file(name: { eq: "estructuras" }, extension: { eq: "webp" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      asesoria: file(name: { eq: "asesoria" }, extension: { eq: "webp" }) {
        childImageSharp {
          fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      estructurasfolder: allFile(filter: {relativeDirectory: {eq: "Estructuras"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      mecanizadofolder: allFile(filter: {relativeDirectory: {eq: "Mecanizado"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      fabyrepfolder: allFile(filter: {relativeDirectory: {eq: "FabyRep"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 500, quality: 100, fit: COVER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

	const gotoPrevious = () => {
		if (openMecanizado) {
			currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
		}
		if (openEstructuras) {
			currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
		}
		if (openFabyRep) {
			currentImageIndex > 0 && setCurrentImageIndex(currentImageIndex - 1);
		}
	};

	const gotoNext = () => {
		if (openMecanizado) {
			currentImageIndex < images.mecanizadofolder.edges.length - 1 && setCurrentImageIndex(currentImageIndex + 1);
		}
		if (openEstructuras) {
			currentImageIndex < images.estructurasfolder.edges.length - 1 && setCurrentImageIndex(currentImageIndex + 1);
		}
		if (openFabyRep) {
			currentImageIndex < images.fabyrepfolder.edges.length - 1 && setCurrentImageIndex(currentImageIndex + 1);
		}
	};

	return (
		<Section id={"servicios"}>
			<ContainerBox>
				<Title>Servicios</Title>
				<ValueProposition>Descubre Nuestros Servicios Especializados</ValueProposition>
				<Container>
					<ContainerImgLeft1>
						<Image fluid={images.mecanizado.childImageSharp.fluid} />
					</ContainerImgLeft1>
					<ContainerTextRight1>
						<ServiceIcon><FaCogs /></ServiceIcon>
						<TitleService>Mecanizado</TitleService>
						<Paragraph>
							Ofrecemos un servicio de alta calidad gracias a la tecnología de punta y la diversa gama de instrumentos de precisión, sumado esto a la experiencia y capacidad de nuestros operadores.
						</Paragraph>
						<Button onClick={() => { setOpenMecanizado(!openMecanizado); }}>
							Ver Galeria <Arrow>&#8594;</Arrow>
						</Button>
						<Lightbox
							isOpen={openMecanizado}
							onPrev={gotoPrevious}
							onNext={gotoNext}
							images={images.mecanizadofolder.edges.map(({ node }) => ({
								src: node.childImageSharp.fluid.src,
								srcSet: node.childImageSharp.fluid.srcSet,
							}))}
							currentIndex={currentImageIndex}
							renderHeader={() => (
								<Header>
									<CloseButton onClick={() => { setOpenMecanizado(false); setCurrentImageIndex(0) }}>
										<CloseIcon />
									</CloseButton>
								</Header>
							)}
							renderPrevButton={() => (
								<PrevButton onClick={gotoPrevious}>
									<PrevIcon />
								</PrevButton>
							)}
							renderNextButton={() => (
								<NextButton onClick={gotoNext}>
									<NextIcon />
								</NextButton>
							)}
							style={{ background: 'rgba(0,0,0,0.43)' }}
							pageTransitionConfig={{
								from: { transform: "scale(0.75)", opacity: 0 },
								enter: { transform: "scale(1)", opacity: 1 },
								leave: { transform: "scale(0.75)", opacity: 0 },
								config: { mass: 1, tension: 320, friction: 32 }
							}}
						/>
					</ContainerTextRight1>
					<ContainerTextLeft2>
						<ServiceIcon><FaWrench /></ServiceIcon>
						<TitleService>Fabricación y Reparación</TitleService>
						<Paragraph>
							Aumenta la vida útil de tus equipos con nuestros servicios de fabricación y reparación. Contamos con personal capacitado para fabricar y reparar todo tipo de equipos, maquinarias presentes en la industria y estructuras metálicas.
						</Paragraph>
						<Button  onClick={() => { setOpenFabyRep(!openFabyRep) }}>
							Ver Galeria <Arrow>&#8594;</Arrow>
						</Button>
						<Lightbox
							isOpen={openFabyRep}
							onPrev={gotoPrevious}
							onNext={gotoNext}
							images={images.fabyrepfolder.edges.map(({ node }) => ({
								src: node.childImageSharp.fluid.src,
								srcSet: node.childImageSharp.fluid.srcSet,
							}))}
							currentIndex={currentImageIndex}
							renderHeader={() => (
								<Header>
									<CloseButton onClick={() => { setOpenFabyRep(false); setCurrentImageIndex(0) }}>
										<CloseIcon />
									</CloseButton>
								</Header>
							)}
							renderPrevButton={() => (
								<PrevButton onClick={gotoPrevious}>
									<PrevIcon />
								</PrevButton>
							)}
							renderNextButton={() => (
								<NextButton onClick={gotoNext}>
									<NextIcon />
								</NextButton>
							)}
							style={{ background: 'rgba(0,0,0,0.43)' }}
							pageTransitionConfig={{
								from: { transform: "scale(0.75)", opacity: 0 },
								enter: { transform: "scale(1)", opacity: 1 },
								leave: { transform: "scale(0.75)", opacity: 0 },
								config: { mass: 1, tension: 320, friction: 32 }
							}}
						/>
					</ContainerTextLeft2>
					<ContainerImgRight2>
						<Image fluid={images.fabyrep.childImageSharp.fluid} />
					</ContainerImgRight2>
					<ContainerImgLeft3>
						<Image fluid={images.estructuras.childImageSharp.fluid} />
					</ContainerImgLeft3>
					<ContainerTextRight3>
						<ServiceIcon><FaBuilding /></ServiceIcon>
						<TitleService>Estructuras Metálicas</TitleService>
						<Paragraph>
							Diseños exclusivos y productos seriados que mejoran la eficiencia. Nuestra empresa cuenta con un área de estructuras metálicas de tamaño medio, que nos permite desarrollar trabajos especiales de estructura y diseños exclusivos y además fabricación de productos seriados y soldaduras en general.
						</Paragraph>
						<Button  onClick={() => { setOpenEstructuras(true) }}>
							Ver Galeria <Arrow>&#8594;</Arrow>
						</Button>
						<Lightbox
							isOpen={openEstructuras}
							onPrev={gotoPrevious}
							onNext={gotoNext}
							images={images.estructurasfolder.edges.map(({ node }) => ({
								src: node.childImageSharp.fluid.src,
								srcSet: node.childImageSharp.fluid.srcSet,
							}))}
							currentIndex={currentImageIndex}
							renderHeader={() => (
								<Header>
									<CloseButton onClick={() => { setOpenEstructuras(false); setCurrentImageIndex(0) }}>
										<CloseIcon />
									</CloseButton>
								</Header>
							)}
							renderPrevButton={() => (
								<PrevButton onClick={gotoPrevious}>
									<PrevIcon />
								</PrevButton>
							)}
							renderNextButton={() => (
								<NextButton onClick={gotoNext}>
									<NextIcon />
								</NextButton>
							)}
							style={{ background: 'rgba(0,0,0,0.43)' }}
							pageTransitionConfig={{
								from: { transform: "scale(0.75)", opacity: 0 },
								enter: { transform: "scale(1)", opacity: 1 },
								leave: { transform: "scale(0.75)", opacity: 0 },
								config: { mass: 1, tension: 320, friction: 32 }
							}}
						/>
					</ContainerTextRight3>
					<ContainerTextLeft4>
						<ServiceIcon><FaLightbulb /></ServiceIcon>
						<TitleService>Asesorías</TitleService>
						<Paragraph>
							Mejora tus procesos productivos con nuestras asesorías especializadas. Ofrecemos servicios de asesorías en terreno, mejoras y diseños de distintos tipos de equipos presentes en la industria para mejorar los procesos productivos.
						</Paragraph>
					</ContainerTextLeft4>
					<ContainerImgRight4>
						<Image fluid={images.asesoria.childImageSharp.fluid} />
					</ContainerImgRight4>
				</Container>
			</ContainerBox>
		</Section>
	);
}

export default Servicios;